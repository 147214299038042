<template>
  <amplify-authenticator>
  <v-app>
    <v-app-bar
        absolute
        color="white"
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
        prominent
      >
        <v-img
          class="mx-2 clinops-logo"
          src="./../assets/clinops-logo.png"
          max-height="120"
          max-width="400"
        ></v-img>
  
        <v-spacer></v-spacer>
        
        <v-img
          class="mx-2"
          src="./../assets/bmx-logo.png"
          max-height="180"
          max-width="180"
        ></v-img>
      </v-app-bar>
    <v-main style="margin-top: 47px">
      <v-container class="main-bg" fluid padless>
        <div class="container">
          <v-row>
            <v-col
              sm="12"
              md="10"
              class="pt-16"
            >
              <div class="inner mt-16">
                <div class="player-wrapper">
                  <div class="aspect-spacer"></div>
                  <div class="pos-absolute full-width full-height top-0">
                    <video id="video-player" class="el-player" poster="./../assets/coming-soon-black.png" controls playsinline></video>
                  </div>
                </div>
                <div class="custom-container">
                  <div class="quiz-wrap">
                    <div id="waiting">
                    </div>
                    <div id="quiz" class="card drop">
                      <div id="card-inner">
                        <h2 id="question"></h2>
                        <v-row>
                          <v-col sm="4" md="4"></v-col>
                          <v-col sm="4" md="4">
                            <v-btn
                              right
                              outlined
                              color="#3C9845"
                              class="ma-2"
                              @click="confirmChoice"
                              style="text-align: center;"
                              :disabled="!selectedAnswer"
                            >
                              Confirm choice
                              <v-icon
                                right
                                dark
                              >
                                mdi-checkbox-marked-circle 
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col sm="12" md="6">
                            <div id="answers" class="answer-container"></div>
                          </v-col>
                          <v-col sm="12" md="6">
                            <div id="answers2" class="answer-container"></div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                  <div class="text-event">
                    <span class="font-weight-bold font-size4rem">1st Success Stories</span><br/>
                    <span class="font-weight-bold font-size7rem" style="border-bottom: 5px solid #c2ff33;">WEBCAST</span><br/>
                    <span class="font-size4rem">SEPTEMBER 15TH 2021</span>
                    <h4 class="mt-3">Please find <strong><a target="_blank" href="https://biomerieux.sharepoint.com/sites/biomerieux-on-board-communities/transformation-programs/SitePages/FULL-POTENTIAL.aspx">HERE</a></strong> more information about Full Potential (bioMérieux on board)</h4>
                    <h4>For people based in China / Indonesia <strong>AND</strong> working remotely, please use this 
                      <strong>
                        <a target="_blank" href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MjEwYzc3YzMtM2VhMy00MzRjLThmNzQtNzAwNGFkMDhmM2U2%40thread.v2/0?context=%7b%22Tid%22%3a%22aec0543a-cc73-4fa4-9e2a-cf52adf9b788%22%2c%22Oid%22%3a%22ca65bbfe-967c-45e8-92b7-d40a952c390d%22%2c%22IsBroadcastMeeting%22%3atrue%7d&btype=a&role=a">link</a>
                      </strong>
                    </h4>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-main>

    <v-footer
      dark
      padless
    >
      <v-card
        class="flex"
        flat
        tile
        color="#00427F"
      >
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>bioMérieux</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
  </amplify-authenticator>
</template>

<script>
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import {
  PlayerEventType,
  PlayerState
} from "amazon-ivs-player";

export default {
  name: "Player",
  props: {
    player: null,
  },
  data() {
    return {
      options: [],
      selectedAnswer: "",
    }
  },
  mounted() {
    let that = this;
    that.setOptions()
    
    const IVSPlayer = document.getElementById("ivsPlayer");
    IVSPlayer.async = true;

    const player = window.IVSPlayer.create();
    console.log("IVS Player version:", player.getVersion());
    player.attachHTMLVideoElement(document.getElementById("video-player"));

    player.addEventListener(PlayerState.PLAYING, function() {
      console.log("Player State - PLAYING");
    });

    player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, function (cue) {
      const data = cue.text;
      console.log(data);
      const metadataText = {
        question: "It's time to vote, please select your favorite success story.",
        answers: that.options
      };
  
      if (document.fullscreen) {
        document.exitFullscreen();
      }
  
      that.triggerQuiz(metadataText);
    });
    player.addEventListener(PlayerState.READY, function() {
      console.log("Player State - READY");
    });

    player.addEventListener(PlayerEventType.ERROR, function(err) {
      console.warn("Player Event - ERROR:", err);
    });

    player.setAutoplay(true);
    player.load(this.streamUrl);
    player.setVolume(1.0);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  computed: {
    streamUrl() {
      if (window.location.hostname.includes('clinopsfullpotential.biomerieux.com')) {
        return "https://a1ae9e188e3c.eu-west-1.playback.live-video.net/api/video/v1/eu-west-1.752764155941.channel.MBN14u13mcc9.m3u8"
      } else {
        return "https://a1ae9e188e3c.eu-west-1.playback.live-video.net/api/video/v1/eu-west-1.752764155941.channel.LAzr5c40F3Nw.m3u8"
      }
    },
    apiStage() {
      if (window.location.hostname.includes('clinopsfullpotential.biomerieux.com')) {
        return "Prod"
      } else {
        return "Stage"
      }
    }
  },
  methods: {
    confirmChoice() {
      let that = this
      const obj = this.options.find(x => x.title === this.selectedAnswer);
      const headers = {
        'Content-Type': 'application/json'
      }
      axios.post(`https://lfstxiipue.execute-api.eu-west-1.amazonaws.com/${this.apiStage}/votes`, {"id": uuidv4(), "vote": obj.id},  headers).then(response => {
        console.log(response)
        that.removeCard()
      })
    },
    videoFullScreen() {
      let elem = document.getElementById("video-player");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    },
    setOptions() {
      let that = this;
      axios.get(`https://lfstxiipue.execute-api.eu-west-1.amazonaws.com/${this.apiStage}/options`).then(response => { 
        that.options = response.data 
        that.options.sort((a,b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : ((parseInt(b.id) > parseInt(a.id)) ? -1 : 0))
      })
    },
    removeCard() {
      setTimeout(function () {
        document.getElementById("quiz").classList.toggle("drop");
      }, 1050);
    },
    triggerQuiz(metadataText) {
      let obj = metadataText;

      let waitMessage = document.getElementById("waiting");
      let questionEl = document.getElementById("question");
      let answersEl = document.getElementById("answers");
      let answersEl2 = document.getElementById("answers2");
      let cardInnerEl = document.getElementById("card-inner");

      this.reinitializeCss(waitMessage, cardInnerEl)
      
      while (answersEl.firstChild) answersEl.removeChild(answersEl.firstChild);
      while (answersEl2.firstChild) answersEl2.removeChild(answersEl2.firstChild);
      
      questionEl.textContent = obj.question;
      
      for (var i = 0; i < obj.answers.length; i++) {
        this.createAnswers(answersEl, answersEl2, cardInnerEl, waitMessage, obj, i);
      }
      cardInnerEl.style.display = "";
    },
    reinitializeCss(waitMessage, cardInnerEl) {
      document.getElementById("quiz").style.display = "";
      document.getElementById("quiz").classList.remove("drop");
      waitMessage.style.display = "none";
      cardInnerEl.style.display = "none";
      cardInnerEl.style.pointerEvents = "auto";
    },
    createAnswers(answersEl, answersEl2, cardInnerEl, waitMessage, obj, i) {
      let btn = document.createElement("button");
      let h4 = document.createElement("h4");
      let qText = document.createTextNode(obj.answers[i].title);
      let description = document.createElement("small");
      let dText = document.createTextNode(obj.answers[i].description);
      
      i < obj.answers.length / 2 ? answersEl.appendChild(btn) : answersEl2.appendChild(btn)
      
      btn.classList.add("answer");
      h4.appendChild(qText);
      
      description.appendChild(dText)
      
      btn.appendChild(h4);
      btn.appendChild(description);

      btn.addEventListener("click", () => {
        this.answerClicked(cardInnerEl, btn)
        return false;
      });
    },
    answerClicked(cardInnerEl, btn) {
      if (btn.classList.contains('clicked')) {
        btn.classList.remove('clicked');
        this.selectedAnswer = ''
      } else {
        btn.classList.toggle("clicked");
        this.selectedAnswer = btn.childNodes[0].textContent
        let answersEl = document.getElementById("answers");
        let answersEl2 = document.getElementById("answers2");
        
        for (let i = 0; i < answersEl.childNodes.length; i++) {
          if (btn.childNodes[0].textContent.trim() !== answersEl.childNodes[i].childNodes[0].textContent && answersEl.childNodes[i].classList.contains('clicked')) {
            answersEl.childNodes[i].classList.remove('clicked');
          }
        }
        
        for (let i = 0; i < answersEl2.childNodes.length; i++) {
          if (btn.childNodes[0].textContent.trim() !== answersEl2.childNodes[i].childNodes[0].textContent && answersEl2.childNodes[i].classList.contains('clicked')) {
            answersEl2.childNodes[i].classList.remove('clicked');
          }
        }
      }
       
      
    }
  },
};
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}
ul[class],
ol[class] {
  padding: 0;
}
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
figure,
blockquote,
dl,
dd {
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
ul[class],
ol[class] {
  list-style: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
img {
  max-width: 100%;
  display: block;
}
article > * + * {
  margin-top: 1em;
}
input,
button,
textarea,
select {
  font: inherit;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
body {
  overflow: hidden;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", sans-serif;
  user-select: none;
}
#app {
  background: #00427F;
  height: 100%;
}
#video-player:-webkit-full-screen  {
  width: 100%;
  height: 100%;
}
.main-bg {
  background-image: url(./../assets/main-bg.png);
  background-position: center center;
  padding: 0 !important;
}
.font-size4rem {
  font-size: 4rem
}
.font-size7rem {
  font-size: 7rem
}
.custom-container {
  position: relative;
  width: 100%;
}
.text-event {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  color: white;
  text-align: center;
  padding: 0;
  margin: 0;
}
.inner {
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  margin: 0 auto;
  padding: 40px;
}
.player-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  border-radius: var(--radius);
  border: 5px solid beige;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.aspect-spacer {
  padding-bottom: 56.25%;
}
.el-player {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: var(--radius);
}
video {
  width: 100%;
  border-radius: var(--radius);
  background: #000;
}
.quiz-wrap {
  min-height: 460px;
  position: relative;
  transition: all 0.25s ease-in;
}
.card {
  margin: 0 20px;
  padding: 20px;
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 1s cubic-bezier(1, -0.56, 0, 1);
  transform: translate3d(0, 0, 0) scale(1);
  backface-visibility: hidden;
  z-index: 1;
}
.card.drop {
  opacity: 0;
  transform: translate3d(0, 200px, -20px) scale(0.92);
}
h2 {
  font-size: 25px;
  text-align: center;
  padding-bottom: 20px;
}
.answer-container > .answer {
  font-size: 18px;
  text-decoration: none;
  border: 1px solid #d5dbdb;
  border-radius: 20px;
  padding: 2px 24px 0 24px;
  margin: 10px 0;
  background: #fafafa;
  color: #545b64;
  transition: all .05s ease-in-out;
  width: 100%;
  text-align: left;
  cursor: pointer;
}
.answer > h4 {
  color: #00427F !important;
}
.answer > small {
  font-size: 73% !important;
}
.answer-container > .answer:hover {
  background: #ebebeb;
}
.answer-container > .answer:active {
  /*background: #ff9900;*/
  /*border: 1px solid #eb5f07;*/
  /*color: #fff;*/
}
.answer-container > .answer.correct {
  background: #25a702;
  border: 1px solid #1d8102;
  color: #fff;
  animation: blink 0.45s infinite;
}
.answer-container > .answer.clicked {
  background: #00427F;
  border: 1px solid #00305c;
  color: #fff;
  /*animation: blink 0.45s infinite;*/
  box-shadow: 2px 4px 5px rgb(0 0 0 / 50%);
}
.answer-container > .answer.clicked > h4 {
  color: #fff !important;
}
.answer-container > .answer.wrong {
  background: #d13212;
  border: 1px solid #b7290d;
  color: #fff;
  animation: blink 0.45s infinite;
}
#waiting {
  top: 100px;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
}
.waiting-text {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 18px;
  color: #d5dbdb;
}
.float {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}
.pos-absolute {
  position: absolute !important;
}
.pos-fixed {
  position: fixed !important;
}
.pos-relative {
  position: relative !important;
}
.top-0 {
  top: 0 !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.show {
  transition: opacity 180ms;
}
.hide {
  opacity: 0;
}
@keyframes blink {
  50% {
    opacity: 0.8;
  }
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 18px;
    padding-bottom: 5px !important;
  }
  .card {
    top: -270px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    margin: -20px !important;
  }
  .answer {
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    display: flex;
    text-decoration: none;
    border: 1px solid #d5dbdb;
    border-radius: 50px;
    padding: 0 24px;
    margin: 10px 0;
    background: #fafafa;
    color: #545b64;
    transition: all .05s ease-in-out;
  }
  .inner {
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: stretch;
    margin: 0 auto;
    padding: 15px 0 0 0 !important;
  }
  .font-size4rem {
    font-size: 2rem
  }
  .font-size7rem {
    font-size: 4rem
  }
  .clinops-logo {
    width: 220px !important;
  }
  .v-toolbar__content {
    padding-left: 0 !important;
  }
  .text-event {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    color: white;
    text-align: center;
    padding: 0;
    margin-top: 20px !important;
  }
}
@media (min-width: 767px) {
  .card {
    top: -135%;
  }
}
</style>
